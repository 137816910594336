import styled from 'styled-components'

export default styled.div`
  position: absolute;
  right: 0px;
  width: 80px;
  height: 100%;
  background: ${(props) => props.theme.darkBackground};
  &.expand {
    width: 240px;
  }
  .notification-badge-container {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    .badge {
      width: 23px;
      height: 23px;
      cursor: pointer;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .dot-badge {
        width: 8px;
        height: 8px;
        background: ${(props) => props.theme.dotBadgeColor};
        border-radius: 100%;
        border: 2px solid white;
        position: absolute;
        right: 1px;
        display: none;
        &.show {
          display: block;
        }
      }
    }
  }
  .notification-feed-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .notification-feed-header {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 5px 20px;
      font-family: 'Prompt-Medium', sans-serif;
      .title {
        height: 20px;
        opacity: 0.5;
        font-size: 10px;
        line-height: 2;
        color: white;
        font-family: inherit;
      }
      .see-less {
        height: 20px;
        font-size: 10px;
        line-height: 2;
        text-align: right;
        color: ${(props) => props.theme.greenFont};
        cursor: pointer;
        font-family: inherit;
      }
    }
    .notification-line {
      margin-left: 20px;
      margin-right: 20px;
      color: rgba(255, 255, 255, 0.1);
    }
    .notification-feed-container {
      height: 100%;
      overflow-y: auto;
    }
    .notification-feed-footer {
      height: 140px;
      padding: 30px 20px;
      .notification-feed-footer-title {
        font-family: 'Prompt', sans-serif;
        color: white;
        height: 20px;
        font-size: 12px;
        line-height: 1.11;
        margin-bottom: 20px;
      }
      button {
        width: 200px;
        font-family: 'Prompt-Medium', sans-serif;
        font-size: 14px;
      }
    }
  }
`
