/* eslint-disable no-unused-vars */
import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as NotificationActions from './actions'
import * as SocketActions from './socket'
import * as ProjectActions from '../ProjectDetailPage/actions'
import DivideLine from '../../components/DivideLine/Loadable'
import NotificationFeed from '../../components/NotificationFeed/Loadable'
import Button from '../../components/Button/Loadable'
import BellIcon from '../../asset/images/notification-sidebar/bell.svg'
import NotificationSidebarStyled from './styledComponent'
import moment from 'moment'
import { FETCH_STATUS_REQUEST } from '../../utils'

import MESSAGE from './message'

const NotificationSidebar = (props) => {
  const getNotificationClassName = () => {
    return props.expand ? 'expand' : ''
  }

  const getMessage = (property) => {
    const msg = MESSAGE[props.language || 'TH']
    return msg[property]
  }

  const getContent = () => {
    return props.expand ? getContentFeeds() : getNotificationBadgeBtn()
  }

  const getSiteTitle = () => {
    let output = ''
    if (props.dashboardPage.loadDashboard.fetchStatus !== FETCH_STATUS_REQUEST && props.dashboardPage.siteDetailData) {
      output = props.dashboardPage.siteDetailData.name.charAt(0).toUpperCase() + props.dashboardPage.siteDetailData.name.slice(1) //capitalize first letter according to P'Peace PM
    } else {
      output = props.eachViolationDetailPage?.siteDetail?.name
    }
    return output
  }

  const handleFeedbackClicked = () => {
    const date = new Date()
    window.open(
      `mailto:farmlab_support@sertiscorp.com?subject=Farmlab - (${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${
        date.getUTCHours() + 7
      }:${date.getUTCMinutes()}) Farm: ${getSiteTitle()}`,
      '_blank'
    )
  }

  const handleHideRightbar = () => {
    props.onHideNotificationSidebar()
  }

  const getContentFeeds = () => {
    return (
      <div className="notification-feed-wrapper" id="notification-feed-wrapper">
        <div className="notification-feed-header">
          <div className="title text-style" id="lbl-activity">
            {getMessage('activity_text')}
          </div>
          <div className="see-less text-style" id="lbl-seeless" onClick={() => handleHideRightbar()}>
            {getMessage('see_less_text') + ' >'}
          </div>
        </div>
        <DivideLine className="notification-line" />
        <div className="notification-feed-container" id="notification-feed-container">
          {generateFeeds()}
        </div>
        <DivideLine className="notification-line" />
        <div className="notification-feed-footer">
          <div className="notification-feed-footer-title text-style">{getMessage('send_feedback')}</div>
          <Button onClick={() => handleFeedbackClicked()} text={getMessage('feedback_button')} invert />
        </div>
      </div>
    )
  }

  const renderViolationNotification = (data) => {
    return `${data.notification_object.violation_name} | ${data.notification_object.camera_name}`
  }

  const renderCameraNotification = (data) => {
    return `${data.notification_object.camera_name} is ${data.notification_object.camera_status.toUpperCase()}`
  }

  const onNotiClick = (data) => {
    props.onEachNotificationClick(data.notification_object.camera_id, data.notification_object.site_id)
    props.readNotification(data.id)
  }

  const modNotificationList = () => {
    return props.notification.feeds.filter((notification, index, self) => {
      return index === self.findIndex((noti) => noti.id === notification.id && noti.time === notification.time)
    })
  }

  const generateFeeds = () => {
    const notificationFeedMod = modNotificationList()
    return notificationFeedMod.map((feedData, index) => {
      return (
        <NotificationFeed
          key={`${feedData.id}`}
          title={feedData.notification_type === 'Violation' ? renderViolationNotification(feedData) : renderCameraNotification(feedData)}
          message={moment(feedData.time).fromNow()}
          unread={feedData.read_status === false}
          onClick={() => onNotiClick(feedData)}
        />
      )
    })
  }

  const handleExpandNotificationSidebar = () => {
    props.onExpandNotificationSidebar()
    props.expandSidebartoHideBadge()
  }

  const showAlertBadge = () => {
    let badgeClassName = 'dot-badge '
    badgeClassName += props.notification.isUpdatingNewNotification ? 'show' : ''
    return <div className={badgeClassName}>&nbsp;</div>
  }

  const getNotificationBadgeBtn = () => {
    return (
      <div className="notification-badge-container">
        <div className="badge" onClick={() => handleExpandNotificationSidebar()}>
          {showAlertBadge()}
          <img src={BellIcon} alt="" />
        </div>
      </div>
    )
  }
  return <NotificationSidebarStyled className={getNotificationClassName()}>{getContent()}</NotificationSidebarStyled>
}

NotificationSidebar.propTypes = {
  notification: PropTypes.shape({
    feeds: PropTypes.array.isRequired
  }).isRequired,
  expand: PropTypes.bool.isRequired,
  onHideNotificationSidebar: PropTypes.func.isRequired,
  onExpandNotificationSidebar: PropTypes.func.isRequired,
  onEachNotificationClick: PropTypes.func,
  location: PropTypes.object,
  language: PropTypes.string.isRequired,
  expandSidebartoHideBadge: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    notification: state.notification,
    dashboardPage: state.dashboardPage,
    eachViolationDetailPage: state.eachViolationDetailPage
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, NotificationActions, SocketActions, ProjectActions), dispatch)
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(NotificationSidebar))
