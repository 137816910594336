const MESSAGE = {
  EN: {
    send_feedback: 'Send us feedback',
    feedback_button: 'Feedback',
    activity_text: 'ACTIVITY',
    see_less_text: 'See Less'
  },
  TH: {
    send_feedback: 'ส่งข้อเสนอแนะ',
    feedback_button: 'ส่ง',
    activity_text: 'แจ้งเตือน',
    see_less_text: 'ซ่อน'
  }
}
export default MESSAGE
